import React, { Component } from 'react'

export class webmapping extends Component {
  render() {
    return (
        <div id="webmapping">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/webmap.jpg" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="webmapping-text">
                <h2>Custom Web Mapping</h2>
                <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.paragraph2 : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.paragraph3 : 'loading...'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default webmapping
