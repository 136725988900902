import React, { Component } from "react";

export class othersvcs extends Component {
  render() {
    return (
      <div id="othersvcs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Geology, Biology and Other Services</h2>
              {/* <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
            </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="othersvcs-text">
                {/* <h2>othersvcs Surveying and Mapping Services</h2> */}
                <p>
                  {this.props.data ? this.props.data.paragraph : "loading..."}
                </p>
                <p>
                  {this.props.data ? this.props.data.paragraph2 : "loading..."}
                </p>
                <p>
                  {this.props.data ? this.props.data.paragraph3 : "loading..."}
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="othersvcs-text">
                <h4>Geology, Biology and Other Services Include:</h4>
                <div className="list-style">
                  <ul>
                    {this.props.data
                      ? this.props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default othersvcs;
