import React, { Component } from "react";

export class civileng extends Component {
  render() {
    return (
      <div id="civileng">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/civileng4.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="civileng-text">
                <h2>Civil Engineering</h2>
                <p>
                  {this.props.data ? this.props.data.paragraph : "loading..."}
                </p>
                <p>
                  {this.props.data ? this.props.data.paragraph2 : "loading..."}
                </p>
                <p>
                  {this.props.data ? this.props.data.paragraph3 : "loading..."}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="civileng-text">
                <h4>Traditional Civil Engineering Services</h4>
                <div className="list-style">
                  <ul>
                    {this.props.data
                      ? this.props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="civileng-text">
                <h4>Water Quality Fee Credits</h4>
                <div className="list-style">
                  <ul>
                    {this.props.data
                      ? this.props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="civileng-text">
                <h4>Low Impact Development/Design (LID)</h4>
                <div className="list-style">
                  <ul>
                    {this.props.data
                      ? this.props.data.Why3.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default civileng;
