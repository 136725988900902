import React, { Component } from 'react'
import Navigation from './components/navigation';
import Header from './components/header';
// import Features from './components/features';
import About from './components/about';
import Services from './components/services';
import Traditional from './components/services/traditional';
import HighDef from './components/services/highdef';
import CivilEng from './components/services/civileng';
import GIS from './components/services/gis';
import WebMapping from './components/services/webmapping';
import OtherSvcs from './components/services/othersvcs';
import Gallery from './components/gallery';
// import Testimonials from './components/testimonials';
import Team from './components/Team';
import Contact from './components/contact';
import JsonData from './data/data.json';

export class App extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        {/* <Features data={this.state.landingPageData.Features} /> */}
        <About data={this.state.landingPageData.About} />
        <Services data={this.state.landingPageData.Services} />
        <Traditional data={this.state.landingPageData.Traditional} />
        <HighDef data={this.state.landingPageData.HighDef} />
        {/* <CivilEng data={this.state.landingPageData.CivilEng} /> */}
        <GIS data={this.state.landingPageData.GIS} />
        {/* <WebMapping data={this.state.landingPageData.WebMapping} /> */}
        <OtherSvcs data={this.state.landingPageData.OtherSvcs} />
        {/* <Gallery /> */}
        {/* <Testimonials data={this.state.landingPageData.Testimonials} /> */}
        {/* <Team data={this.state.landingPageData.Team} /> */}
        <Contact data={this.state.landingPageData.Contact} />
      </div>
    )
  }
}

export default App;
