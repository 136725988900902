import React, { Component } from "react";

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <p>
              The mission of Earthworx, LLC is to offer the highest quality
              services to clients in the most timely and cost effective manner
              possible. Accuracy and efficiency are best achieved by employing
              experienced and highly trained, motivated individuals. The staff
              at Earthworx exceeds this standard and is anxious to assist on any
              project ranging from smaller residential surveys to larger
              engineering projects.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
